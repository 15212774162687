html, body {
  height: 100%;
  color: #b4aaaa;
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
}

body {
  background-color: #082127;
  justify-content: center;
  align-items: center;
  display: flex;
}

body .loading-placeholder {
  display: none;
}

body .loading-placeholder.loading {
  flex-direction: column;
  align-items: center;
  display: flex;
}

body .content {
  display: block;
}

body .content.loading {
  display: none;
}

body .content .caption {
  width: 100%;
  text-align: center;
  z-index: 1;
  font-size: 17px;
  font-weight: 100;
  position: absolute;
  top: 5%;
}

body .content .made-by-pages {
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 5%;
  right: 5%;
}

body .content .made-by-pages .pages-glow-in-the-dark {
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  display: flex;
  position: relative;
}

body .content .made-by-pages .pages-glow-in-the-dark .glowing-star {
  width: 2px;
  height: 2px;
  animation: 1s ease-in-out infinite alternate glow;
  position: absolute;
  left: 50.4%;
  box-shadow: 0 1px 66px 30px #f4a742;
}

.blinking-star.normal {
  animation: 1s steps(2, jump-both) infinite blink;
}

.blinking-star.reverse {
  animation: 1s steps(2, jump-both) infinite reverse blink;
}

.logo-not-ok {
  fill: #b7bbbd52;
}

@keyframes glow {
  from {
    box-shadow: 0 1px 66px 24px #f4a742;
  }

  to {
    box-shadow: 0 1px 66px 33px #f4a742;
  }
}

@keyframes blink {
  from {
    fill: #082127;
  }

  to {
    fill: #f4a742;
  }
}

/*# sourceMappingURL=index.531235c8.css.map */
