html, body {
  height: 100%;
  margin: 0px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: #b4aaaa
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #082127;

  .loading-placeholder {
    display: none;

    &.loading {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .content {
    display: block;

    &.loading {
      display: none;
    }

    .caption {
      position: absolute;
      top: 5%;
      width: 100%;
      font-size: 17px;
      font-weight: 100;
      text-align: center;
      z-index: 1;
    }

    .made-by-pages {
      position: absolute;
      bottom: 5%;
      right: 5%;
      display: flex;
      align-items: center;

      .pages-glow-in-the-dark {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        
        .glowing-star {
          position: absolute;
          left: 50.4%;
          width: 2px;
          height: 2px;
          box-shadow: 0px 1px 66px 30px #f4a742;
          animation: glow 1s ease-in-out infinite alternate;
        }
      }

    }
  }
}

.blinking-star {
  &.normal {
    animation: blink 1s steps(2, jump-both) infinite normal;
  }

  &.reverse {
    animation: blink 1s steps(2, jump-both) infinite reverse;
  }
}

.logo-not-ok {
  fill: #b7bbbd52;
}

@keyframes glow {
  from {
    box-shadow: 0px 1px 66px 24px #f4a742;
  }
  to {
    box-shadow: 0px 1px 66px 33px #f4a742;
  }
}

@keyframes blink {
  from {
    fill: #082127;
  }
  to {
    fill: #f4a742;
  }
}